import React from "react";
import { object, any } from "prop-types";

const ContainerBackground = ({ style, children }) => (
  <div style={{ padding: "16px", ...style }}>{children}</div>
);

ContainerBackground.propTypes = {
  style: object,
  children: any,
};

export default ContainerBackground;
