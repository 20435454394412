import React from "react";
import { Button } from "antd";
import { func, any, object, number } from "prop-types";
import { Colors } from "../constants";

const CtaSecondary = props => (
  <Button
    type={"default"}
    shape={"round"}
    onClick={props.onClick}
    size={"large"}
    {...props}
    style={{
      margin: "4px",
      color: Colors.TEAL,
      borderColor: Colors.TEAL,
      width: props.width,
      fontWeight: "bold",
      ...props.style,
    }}
  >
    {props.children}
  </Button>
);

CtaSecondary.propTypes = {
  children: any.isRequired,
  onClick: func,
  style: object,
  width: number,
};

export default CtaSecondary;
