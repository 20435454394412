import React from "react";
import { Row, Col } from "antd";

import Icon1 from "../../images/icons/icon001.svg";
import Icon2 from "../../images/icons/icon002.svg";
import Icon3 from "../../images/icons/icon003.svg";
import Icon4 from '../../images/icons/icon004.svg'
import { ContentServiceBox } from "./content_service_box";

import { ServicesData } from "../data";

const iconStyles = {
  width: "70px",
  float: "left",
  marginRight: "16px",
};

const getIcon = serviceName => {
  switch (serviceName) {
    case "aeg":
      return <Icon2 style={iconStyles} />;
    case "fcaspays":
      return <Icon3 style={iconStyles} />;
    case "pdbidr":
      return <Icon1 style={iconStyles} />;
    case "pd4castr":
      return <Icon4 style={iconStyles} />;
    default:
      return null;
  }
};

export const ContentServices = () => {
  const services = Object.keys(ServicesData);
  const cols = 24 / services.length;

  return (
    <div>
      <Row gutter={24}>
        {services.map(s => (
          <Col key={s} lg={cols}>
            <ContentServiceBox
              serviceCategory={ServicesData[s].categoryName}
              serviceName={ServicesData[s].title}
              learnMoreLink={ServicesData[s].learnMoreLink}
              freeTrialLink={ServicesData[s].freeTrialLink}
              serviceIcon={getIcon(s)}
              serviceDescription={ServicesData[s].description}
              descriptionList={ServicesData[s].descriptionList}
              targetAudienceList={ServicesData[s].targetAudienceList}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
