import React from "react";
import { object, string, any } from "prop-types";
import { Icon, Row, Col } from "antd";

import { Colors } from "../constants";

const ContactListItem = ({ style, iconName, title, content }) => (
  <div style={{ padding: "16px 0", ...style }}>
    <Row gutter={16}>
      <Col xs={4} md={2}>
        <Icon
          type={iconName}
          style={{
            fontSize: "24px",
            marginTop: "4px",
            color: Colors.LIGHT_BLUE,
          }}
        />
      </Col>
      <Col xs={20} md={22}>
        <div>
          <b>{title}</b>
        </div>
        <div>{content}</div>
      </Col>
    </Row>
  </div>
);

ContactListItem.propTypes = {
  style: object,
  iconName: string.isRequired,
  title: string.isRequired,
  content: any.isRequired,
};

export default ContactListItem;
