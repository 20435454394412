import React from "react";
import { object, string } from "prop-types";
import { LogoIconBanner } from "../logos";
import { Colors } from "../constants";

export const BannerStandard = ({ style, title, subTitle }) => (
  <div
    style={{
      backgroundColor: Colors.DARK_BLUE,
      overflow: "hidden",
      padding: "2em",
      ...style,
    }}
  >
    <div style={{ zIndex: 2, textAlign: "center", position: "relative" }}>
      <h1 style={{ color: "white", fontWeight: "bold", fontSize: "2.1rem" }}>
        {title}
      </h1>
      <h3 style={{ color: "white", fontSize: "1.39em" }}>{subTitle}</h3>
    </div>
    <LogoIconBanner
      style={{
        marginTop: "-210px",
        marginBottom: "-120px",
        float: "right",
        position: "relative",
        right: "16px",
        zIndex: 1,
        width: "340px",
        overflow: "hidden",
        opacity: "0.1",
      }}
    />
  </div>
);

BannerStandard.propTypes = {
  style: object,
  title: string.isRequired,
  subTitle: string,
};
