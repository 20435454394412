export const InternalLinkTypes = {
  Home: "/",
  Terms: "/terms",
  Contact: "/contact",
  About: "/about",
  Services: "/services",
  ServicesAEG: "/services/aeg",
  ServicesFcasPays: "/services/fcaspays",
  ServicesVadvisor: "/services/pdbidr",
  ServicePd4castr: "/services/pd4castr",
  FreeTrial: "/freetrial",
  FreeTrialAEG: "/freetrial?service=aeg",
  FreeTrialFcasPays: "/freetrial?service=fcaspays",
  FreeTrialVadvisor: "/freetrial?service=pdbidr",
};
