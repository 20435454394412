import React from "react";
import { object, array, string } from "prop-types";

import { ContainerBackground } from "../containers";
import { Colors } from "../constants";
import { CtaPrimary } from "../buttons";
import { InternalLink } from "../links";

export const ContentServiceTargetAudience = ({
  style,
  freeTrialLink,
  targetAudienceList,
}) => (
  <ContainerBackground
    style={{
      textAlign: "center",
      padding: "1rem 10%",
      background: Colors.DARK_BLUE,
      color: "white",
      ...style,
    }}
  >
    <p
      style={{
        color: "inherit",
        fontSize: "1.35rem",
        display: "inline-block",
        marginRight: "16px",
      }}
    >
      This service is consumed by <b>{targetAudienceList.join(", ")}</b>
    </p>
   {freeTrialLink && <CtaPrimary>
      <InternalLink url={freeTrialLink}>FREE TRIAL</InternalLink>
    </CtaPrimary>}
  </ContainerBackground>
);

ContentServiceTargetAudience.propTypes = {
  style: object,
  freeTrialLink: string.isRequired,
  targetAudienceList: array.isRequired,
};
