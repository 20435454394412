import React from "react";
import { object } from "prop-types";
import LogoWhite from "../../images/pdview-logo-white.svg";

const LogoFooter = ({ style }) => (
  <span style={{ display: "inline-block", ...style }}>
    <LogoWhite style={{ width: "100%", height: "auto" }} />
  </span>
);

LogoFooter.propTypes = {
  style: object,
};

export default LogoFooter;
