import React from "react";
import { object, number } from "prop-types";
import { Icon } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider_clients.css";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import { Colors } from "../constants";
// import { Row, Col } from "antd";

import AGL from "../../images/clients/AGL.svg";
import Alinta from "../../images/clients/Alinta_Energy.svg";
import Arrow from "../../images/clients/Arrow_Energy.svg";
import Bold from "../../images/clients/Bold_Trading.svg";
import CCI from "../../images/clients/Castleton_Commodities.svg";
import CleanCo from "../../images/clients/CleanCo_QLD.svg";
import CS from "../../images/clients/CS_Energy.svg";
import Delta from "../../images/clients/Delta_Electricity.svg";
import EA from "../../images/clients/EnergyAustralia.svg";
import Engie from "../../images/clients/Engie.svg";
import Epoch from "../../images/clients/Epoch.svg";
import Erm from "../../images/clients/Erm_Power.svg";
import Hartree from "../../images/clients/Hartree.svg";
import MFT from "../../images/clients/mft.svg"
import Mako from "../../images/clients/mako.svg"
import OriginEnergy from "../../images/clients/Origin.svg";
import Simec from "../../images/clients/Simec.svg";
import SnowyHydro from "../../images/clients/Snowy_Hydro.svg";
import Stanwell from "../../images/clients/Stanwell.svg";
import Shell from '../../images/clients/shell.svg'
import Trafigura from '../../images/clients/trafigura.svg'

const SliderClients = props => {
  const data = useStaticQuery(graphql`
    query {
     lifetrading: file(relativePath: { eq: "clients/lifetrading.png"}) {
        childImageSharp {
           fixed(height: 65) {
              ...GatsbyImageSharpFixed
           }
        }
     }
      vivcourt: file(relativePath: { eq: "clients/vivcourt.png" }) {
        childImageSharp {
          fixed(height: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vitol: file(relativePath: { eq: "clients/vitol.png" }) {
        childImageSharp {
          fixed(height: 120) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rwe: file(relativePath: { eq: "clients/rwe.png" }) {
        childImageSharp {
          fixed(height: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ergon: file(relativePath: { eq: "clients/ergon.jpeg" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pacifcenergy: file(relativePath: { eq: "clients/pacifcenergy.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const renderImage = (image, width) => {
    if (data[image])
      return (
        <Img
          style={{ width: width? width:"150px", height: "65px", display: "inline-block", objectFit:'contain' }}
          fixed={data[image].childImageSharp.fixed}
          objectFit='contain'
        />
      );
    else return image;
  };

  const arrowStyles = {
    display: "inline-block",
    fontSize: "2.5rem",
    position: "absolute",
    top: "0",
    color: Colors.TEAL,
    cursor: "pointer",
  };

  const NextArrow = ({ className, style, onClick }) => (
    <span
      style={{ ...style, right: "-40px", ...arrowStyles }}
      onClick={onClick}
    >
      <Icon type={"right"} />
    </span>
  );

  const PrevArrow = ({ className, style, onClick }) => (
    <span style={{ ...style, left: "-40px", ...arrowStyles }} onClick={onClick}>
      <Icon type={"left"} />
    </span>
  );

  const imageStyles = {
    width: "70%",
    height: "60px",
    marginBottom: "32px",
    verticalAlign: "middle",
  };

  const imgContainerStyles = {
    padding: "8px",
  };

  return (
    <div className={"cp-slider-clients"}>
      <Slider
        //dots={true}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        infinite={true}
        autoplaySpeed={7000}
        // speed={20000}
        //   slidesToScroll={1}
        autoplay={true}
        variableWidth={true}
        pauseOnHover={true}
        // responsive={[
        //   {
        //     breakpoint: 800,
        //     settings: {
        //       slidesPerRow: 4,
        //       rows: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 600,
        //     settings: {
        //       slidesPerRow: 2,
        //       rows: 1,
        //     },
        //   },
        // ]}
        {...props}
      >
        <div style={{ ...imgContainerStyles, width: "120px" }}>
          <AGL style={imageStyles} />
        </div>

        <div style={{ ...imgContainerStyles, width: "120px" }}>
          <Alinta style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "200px" }}>
          <Arrow style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "250px" }}>
          <Bold style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "150px" }}>
          <CCI style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "250px" }}>
          <CleanCo style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          <CS style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "150px" }}>
          <Delta style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "200px" }}>
          <EA style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          <Engie style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          <Epoch style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "180px", height:'40px', objectFit:'contain' }}>
          {renderImage("ergon", '85px')}
        </div>
        <div style={{ ...imgContainerStyles, width: "120px" }}>
          <Erm style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "150px" }}>
          <Hartree style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "150x" }}>
          {renderImage("lifetrading")}
        </div>
       
        <div style={{ ...imgContainerStyles, width: "200px" }}>
          <p
            style={{ fontWeight: "bold", marginTop: "8px", fontSize: "1.5rem" }}
          >
            MACQUARIE
          </p>
        </div>
        <div style={{ ...imgContainerStyles, width: "150px" }}>
          <Mako style={imageStyles} />
         </div>
        <div style={{ ...imgContainerStyles, width: "150px" }}>
         <MFT style={imageStyles}/>
         </div>
       
        <div style={{ ...imgContainerStyles, width: "120px" }}>
          <OriginEnergy style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "180px", height:'65px', objectFit:'contain' }}>
          {renderImage("pacifcenergy", "65px")}
        </div>
        <div style={{ ...imgContainerStyles, width: "180px", objectFit:'contain' }}>
          {renderImage("rwe", '65px')}
        </div>
        <div style={{ ...imgContainerStyles, width: "200px" }}>
          <p
            style={{ fontWeight: "bold", marginTop: "8px", fontSize: "1.5rem" }}
          >
            SGM solutions
          </p>
        </div>
        <div style={{ ...imgContainerStyles, width: "120px" }}>
          <Shell style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          <Simec style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          <SnowyHydro style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          <Stanwell style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          <Trafigura style={imageStyles} />
        </div>
        <div style={{ ...imgContainerStyles, width: "170px" }}>
          {renderImage("vitol")}
        </div>
        <div style={{ ...imgContainerStyles, width: "180px" }}>
          {renderImage("vivcourt")}
        </div>
        
      </Slider>
    </div>
  );
};

SliderClients.propTypes = { style: object, rows: number, slidesPerRow: number };

export default SliderClients;
