import React from "react";
import { Button } from "antd";
import { func, any, object, number } from "prop-types";

const CtaPrimary = props => (
  <Button
    type={"primary"}
    shape={"round"}
    onClick={props.onClick}
    size={"large"}
    {...props}
    style={{
      margin: "4px",
      width: props.width,
      fontWeight: "bold",
      ...props.style,
    }}
  >
    {props.children}
  </Button>
);

CtaPrimary.propTypes = {
  children: any.isRequired,
  onClick: func,
  style: object,
  width: number,
};

export default CtaPrimary;
