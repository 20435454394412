import React from "react";
import { object } from "prop-types";
import LogoGray from "../../images/pdview-icon-gray.svg";

export const LogoIconServices = ({ style }) => (
  <span style={{ display: "inline-block", ...style }}>
    <LogoGray style={{ width: "100%", height: "auto" }} />
  </span>
);

LogoIconServices.propTypes = {
  style: object,
};
