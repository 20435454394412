import React from "react";
import { object } from "prop-types";

import { ContactListItem } from "./index";
import { ExternalLink } from "../links";

const ContactInfo = ({ style }) => (
  <div style={{marginTop: "3rem"}}>
    <p>
      For all enquiries, questions, technical support and feedback, please
      contact us via the emails below or via the enquiry form and we will be in
      touch with you.
    </p>
    <div>
      <ContactListItem
        iconName={"mail"}
        title={"General Email"}
        content={<a href={"mailto:info@pdview.com.au"}>info@pdview.com.au</a>}
      />
      <ContactListItem
        iconName={"customer-service"}
        title={"Technical Support Email"}
        content={
          <a href={"mailto:support@pdview.com.au"}>support@pdview.com.au</a>
        }
      />

      <ContactListItem
        iconName={"environment"}
        title={"Headquarters"}
        content={
          <ExternalLink url={"https://g.page/pdview?share"}>
            Suite 3, 109 Hawke St, West Melbourne Vic 3003
          </ExternalLink>
        }
      />
      {/*<ContactListItem*/}
      {/*  iconName={"linkedin"}*/}
      {/*  title={"Follow us online"}*/}
      {/*  content={*/}
      {/*    <ExternalLink url={ExternalLinkTypes.LinkedIn}>*/}
      {/*      pdView's LinkedIn Page*/}
      {/*    </ExternalLink>*/}
      {/*  }*/}
      {/*/>*/}
      {/*<ContactListItem*/}
      {/*  iconName={"youtube"}*/}
      {/*  title={"Watch our videos"}*/}
      {/*  content={*/}
      {/*    <ExternalLink url={ExternalLinkTypes.YoutubeChannel}>*/}
      {/*      pdView's Youtube Channel*/}
      {/*    </ExternalLink>*/}
      {/*  }*/}
      {/*/>*/}
    </div>
  </div>
);

ContactInfo.propTypes = {
  style: object,
};

export default ContactInfo;
