import React from "react";
import { object } from "prop-types";
import LogoBlue from "../../images/pdview-logo-blue.svg";

const LogoHeader = ({ style }) => (
  <span style={{ display: "inline-block", ...style }}>
    <LogoBlue style={{ width: "100%", height: "auto" }} />
  </span>
);

LogoHeader.propTypes = {
  style: object,
};

export default LogoHeader;
