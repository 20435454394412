import React from "react";
import { object, any } from "prop-types";

const ContainerContent = ({ style, children }) => (
  <div style={{ padding: "16px", ...style }}>{children}</div>
);

ContainerContent.propTypes = {
  style: object,
  children: any,
};

export default ContainerContent;
