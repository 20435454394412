import React from "react";
import { object, any } from "prop-types";
import { ContainerBackground } from "./index";

const ContainerBackgroundWhite = ({ style, children }) => (
  <ContainerBackground style={{ backgroundColor: "white", ...style }}>
    {children}
  </ContainerBackground>
);

ContainerBackgroundWhite.propTypes = {
  style: object,
  children: any,
};

export default ContainerBackgroundWhite;
