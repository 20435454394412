import React from "react";
import { object, any } from "prop-types";
import { ContainerBackgroundWhite, ContainerContentFixed } from "../containers";
import { Row, Col } from "antd";

export const ContentServiceDescription = ({ style, image, description }) => (
  <ContainerBackgroundWhite style={{ padding: "2em", ...style }}>
    <ContainerContentFixed>
      <Row gutter={32}>
        <Col md={12} style={{ padding: "16px 32px" }}>
          {description}
        </Col>
        <Col md={12} style={{ padding: "16px 32px" }}>
          {image}
        </Col>
      </Row>
    </ContainerContentFixed>
  </ContainerBackgroundWhite>
);

ContentServiceDescription.propTypes = {
  style: object,
  image: any.isRequired,
  description: any.isRequired,
};
