export const ExternalLinkTypes = {
  SignUp: "https://apps.pdview.com.au/signup",
  Login: "https://apps.pdview.com.au/login",
  YoutubeChannel: "https://www.youtube.com/channel/UC3dXzoe4xiADgopR3vyUiGA",
  LinkedIn: "https://www.linkedin.com/company/pdview/",
  KnowledgeBaseAEG: "https://pdview.atlassian.net/wiki/spaces/DAEGKB/overview",
  KnowledgeBaseFcasPays:
    "https://pdview.atlassian.net/wiki/spaces/FPKB/overview",
  KnowledgeBaseVadvisor:
    "https://pdview.atlassian.net/wiki/spaces/VKB/overview",
  VideoAEG: "https://www.youtube.com/embed/_EcGghRXYKM",
  VideoFcasPays: "",
  VideoVadvisor: "",
};
