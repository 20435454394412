/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { node, object, string } from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Layout, Col, Row } from "antd";

import { Header, Footer, AppMenu } from "./index";
import { LiveChat } from "../live_chat";
import { Colors } from "../constants";
import "./layout.css";

const LayoutCP = ({ children, style, pageLink }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout style={{ backgroundColor: Colors.DARK_BLUE }}>
      <Header siteTitle={data.site.siteMetadata.title} pageLink={pageLink} />
      <Layout>
        <Layout.Content style={style}>
          {children}
          <Footer />
          <LiveChat />
        </Layout.Content>
        <Row>
          <Col lg={0} md={24} sm={24} xs={24}>
            <Layout.Sider
              className={"app-mobile-menu"}
              reverseArrow={true}
              style={{
                zIndex: 11,
                backgroundColor: Colors.DARK_BLUE,
              }}
              theme="dark"
              defaultCollapsed={true}
              collapsedWidth={0}
              // breakpoint={"md"}
              collapsible={true}
              //width={"100%"}
            >
              <AppMenu mode={"inline"} />
            </Layout.Sider>
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

LayoutCP.propTypes = {
  children: node.isRequired,
  style: object,
  pageLink: string,
};

export default LayoutCP;
