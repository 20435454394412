import React from "react";
import { object, arrayOf, any } from "prop-types";
// import { Icon } from "antd";

export const ListHighlights = ({ style, items }) => (
  <ul style={{ paddingLeft: "24px", ...style }}>
    {items.map((item, i) => (
      <li
        key={`highlight-${i}`}
        style={{ marginBottom: "8px", color: "rgb(106,106,106)" }}
      >
        {/*<Icon*/}
        {/*  style={{*/}
        {/*    fontWeight: "bold",*/}
        {/*    color: "#8bc440",*/}
        {/*    marginRight: "8px",*/}
        {/*    marginLeft: "-24px",*/}
        {/*  }}*/}
        {/*  type={"check-circle"}*/}
        {/*/>*/}
        {item}
      </li>
    ))}
  </ul>
);

ListHighlights.propTypes = {
  items: arrayOf(any.isRequired).isRequired,
  style: object,
};
