import React from "react";
import { object, string, node, arrayOf } from "prop-types";
import { Row, Col } from "antd";

import { CtaPrimary } from "../buttons";
import { ListCheckMarks, ListHighlights } from "../lists";
import { InternalLink } from "../links";
import { Colors } from "../constants";
import { LogoIconServices } from "../logos";

export const ContentServiceBox = ({
  style,
  serviceName,
  serviceDescription,
  serviceIcon,
  learnMoreLink,
  freeTrialLink,
  descriptionList,
  targetAudienceList,
  serviceCategory,
}) => (
  <div
    style={{
      background: "#fff",
      border: "1px solid #d9d9d9",
      //borderRadius: "4px",
      textAlign: "left",
      marginTop: "8px",
      minHeight: "100%",
      ...style,
    }}
  >
    <div style={{ padding: "3rem 10%", zIndex: 2, position: "relative" }}>
      {serviceIcon}
      <h1
        style={{ fontWeight: "800", fontSize: "1.2rem", marginBottom: "16px" }}
      >
        {serviceName}
        <br />
        <span style={{ fontWeight: "normal", color: Colors.LIGHT_BLUE }}>
          {serviceCategory}
        </span>
      </h1>
      <h3
        style={{
          fontWeight: "bold",
          fontSize: "1.03rem",
          color: Colors.DARK_BLUE,
        }}
      >
        {serviceDescription}
      </h3>
      <ListHighlights
        items={descriptionList}
        style={{
          marginBottom: "32px",
          fontSize: "0.75rem",
          lineHeight: "1.39rem",
        }}
      />

      <h3 style={{ fontWeight: "bold" }}>This service is consumed by:</h3>
      <ListCheckMarks
        items={targetAudienceList}
        style={{ fontSize: "1rem", lineHeight: "1.39rem" }}
      />
      <br />
      <Row gutter={16}>
        <Col xl={12}>
          <CtaPrimary block={true} style={{ minWidth: "180px" }}>
            <InternalLink url={learnMoreLink}>FIND OUT MORE</InternalLink>
          </CtaPrimary>
        </Col>
        {/*<Col xl={12}>*/}
        {/*  <CtaSecondary block={true}>*/}
        {/*    <InternalLink url={freeTrialLink}>FREE TRIAL</InternalLink>*/}
        {/*  </CtaSecondary>*/}
        {/*</Col>*/}
      </Row>
    </div>
    <div
      style={{
        overflow: "hidden",
        zIndex: 1,
        position: "absolute",
        bottom: "1px",
        right: "5%",
      }}
    >
      <LogoIconServices
        style={{
          position: "relative",
          width: "300px",
          bottom: "-100px",
          opacity: "1",
        }}
      />
    </div>
  </div>
);

ContentServiceBox.propTypes = {
  style: object,
  serviceName: string.isRequired,
  serviceDescription: string.isRequired,
  serviceIcon: node.isRequired,
  learnMoreLink: string.isRequired,
  freeTrialLink: string.isRequired,
  categoryName: string.isRequired,
  descriptionList: arrayOf(string.isRequired),
  targetAudienceList: arrayOf(string.isRequired),
};
