import React from "react";
import { object } from "prop-types";
import { Layout, Row, Col } from "antd";

import { LogoFooter, LogoIcon } from "../logos";
import { ListArrows } from "../lists";

import {
  ExternalLink,
  ExternalLinkTypes,
  InternalLink,
  InternalLinkTypes,
} from "../links";
import { Colors } from "../constants";

const Footer = ({ style }) => {
  // const services = Object.keys(ServicesData);

  return (
    <Layout.Footer
      style={{
        backgroundColor: Colors.DARK_BLUE,
        overflow: "hidden",
        padding: "32px 10%",
        color: "#eee",
        ...style,
      }}
    >
      <Row style={{ zIndex: 2 }} gutter={16}>
        <Col md={7}>
          <LogoFooter style={{ maxWidth: "150px", display: "block" }} />
          <h2 style={{ color: "#fff", fontWeight: "100", fontSize: "1.28rem" }}>
            Specialising in Algorithmic Software Services for Energy Markets
          </h2>
          <h4 style={{ color: "#fff", fontWeight: "100", fontSize: "1.05rem" }}>
            Proudly Australian owned and operated
          </h4>
        </Col>
        <Col md={3} style={{ fontSize: "0.89rem" }}>
          <ListArrows
            items={[
              <InternalLink url={InternalLinkTypes.Home}>Home</InternalLink>,
              <InternalLink url={InternalLinkTypes.Services}>
                Our Services
              </InternalLink>,
              <InternalLink url={InternalLinkTypes.About}>
                About Us
              </InternalLink>,
              <InternalLink url={InternalLinkTypes.Contact}>
                Contact Us
              </InternalLink>,
            ]}
          />
        </Col>
        <Col md={3} style={{ fontSize: "0.89rem" }}>
          <ListArrows
            items={[
              <InternalLink url={InternalLinkTypes.freeTrialLink}>
                Free trial
              </InternalLink>,
              <ExternalLink url={ExternalLinkTypes.loginLink}>
                Login
              </ExternalLink>,
            ]}
          />
        </Col>
        <Col md={3}>
          {/*<div style={{ textAlign: "center" }}>*/}
          {/*  <b style={{ color: "white" }}>Follow us online</b>*/}
          {/*  <div>*/}
          {/*    <ExternalLink url={ExternalLinkTypes.LinkedIn}>*/}
          {/*      <CtaPrimary shape={"circle"} icon={"linkedin"} ghost />*/}
          {/*    </ExternalLink>*/}
          {/*    <ExternalLink url={ExternalLinkTypes.YoutubeChannel}>*/}
          {/*      <CtaPrimary shape={"circle"} icon={"youtube"} ghost />*/}
          {/*    </ExternalLink>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Col>
        {/*{services.map(s => (*/}
        {/*  <Col md={4} key={s}>*/}
        {/*    <h3*/}
        {/*      style={{ color: "white", fontWeight: "bold", marginLeft: "20px" }}*/}
        {/*    >*/}
        {/*      {ServicesData[s].title}*/}
        {/*    </h3>*/}
        {/*    <ListArrows*/}
        {/*      items={[*/}
        {/*        <InternalLink url={ServicesData[s].learnMoreLink}>*/}
        {/*          Service information*/}
        {/*        </InternalLink>,*/}
        {/*        <ExternalLink url={ServicesData[s].kbLink}>*/}
        {/*          Knowledge base*/}
        {/*        </ExternalLink>,*/}
        {/*        <InternalLink url={ServicesData[s].freeTrialLink}>*/}
        {/*          Free trial*/}
        {/*        </InternalLink>,*/}
        {/*        <ExternalLink url={ServicesData[s].loginLink}>*/}
        {/*          Login*/}
        {/*        </ExternalLink>,*/}
        {/*      ]}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*))}*/}
      </Row>

      <small
        style={{
          color: "#aaa",
          display: "block",
          textAlign: "left",
        }}
      >
        © {new Date().getFullYear()} pdView Pty Ltd. All rights reserved |{" "}
        <InternalLink url={InternalLinkTypes.Terms}>Terms of use</InternalLink>
      </small>

      {/*<BackTop style={{ left: "49%" }} />*/}
      <Col xs={0} sm={0} md={24}>
      <LogoIcon
        style={{
          maxHeight: "400px",
          marginTop: "-475px",
          width: "440px",
          position: "relative",
          bottom: "-200px",
          float: "right",
          zIndex: 1,
          //opacity: 0.1,
        }}
      />
      </Col>
    </Layout.Footer>
  );
};

Footer.propTypes = {
  style: object,
};

export default Footer;
