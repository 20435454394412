import React from "react";
import { object } from "prop-types";

// import Atlassian from "../../images/tech/atlassian.svg";
import Aws from "../../images/tech/aws.svg";
import ReactJs from "../../images/tech/reactjs.svg";
import Node from "../../images/tech/nodejs.svg";
import Python from "../../images/tech/python.svg";

export const SliderTechStack = ({ style }) => {
  const logoStyles = {
    display: "inline-block",
    margin: "16px 32px",
    height: "120px",
    width: "auto",
    //maxWidth: "15%"
  };

  return (
    <div style={{ textAlign: "center", ...style }}>
      <span>
        <Aws style={{ ...logoStyles, maxWidth: "150px" }} />
      </span>
      <span>
        <ReactJs style={{ ...logoStyles, maxWidth: "100px" }} />
      </span>
      <span>
        <Python style={{ ...logoStyles, maxWidth: "120px" }} />
      </span>
      <span>
        <Node style={{ ...logoStyles, maxWidth: "100px" }} />
      </span>
      {/*<span>*/}
      {/*  <Atlassian style={logoStyles}/>*/}
      {/*</span>*/}
    </div>
  );
};

SliderTechStack.propTypes = {
  style: object,
};
