import React from "react";
import { object, array, any, bool, string } from "prop-types";
import { Row, Col } from "antd";

import { ContainerBackground, ContainerContentFixed } from "../containers";
import { ListHighlights } from "../lists";
import { Colors } from "../constants";

export const ContentServiceModule = ({
  style,
  image,
  title,
  subTitle,
  descriptionList,
  isBackgroundWhite,
  isImageRightAligned,
}) => (
  <ContainerBackground
    style={{
      backgroundColor: isBackgroundWhite ? "#fff" : Colors.LIGHT_GRAY,
      ...style,
    }}
  >
    <ContainerContentFixed>
      <Row gutter={16}>
        <Col
          md={{ span: 16, push: isImageRightAligned ? 8 : 0 }}
          style={{ padding: "16px 32px" }}
        >
          <div style={{ border: `8px solid ${Colors.LIGHTER_BLUE}`}}>
            {image}
          </div>
        </Col>
        <Col
          md={{ span: 8, pull: isImageRightAligned ? 16 : 0 }}
          style={{ padding: "16px 32px" }}
        >
          <h2 style={{ color: Colors.DARK_BLUE, fontWeight: "bold" }}>
            {title}
          </h2>
          {subTitle && <h3 style={{ color: Colors.LIGHT_BLUE, fontWeight: "400" }}>
            {subTitle}
          </h3>}
          {descriptionList && (
            <ListHighlights
              items={descriptionList}
              style={{ fontSize: "0.75rem" }}
            />
          )}
        </Col>
      </Row>
    </ContainerContentFixed>
  </ContainerBackground>
);

ContentServiceModule.propTypes = {
  style: object,
  image: any.isRequired,
  title: string.isRequired,
  subTitle: string,
  descriptionList: array.isRequired,
  isBackgroundWhite: bool,
  isImageRightAligned: bool,
};
