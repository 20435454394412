import React from "react";
import { object, arrayOf, any } from "prop-types";
import { Icon } from "antd";

export const ListArrows = ({ style, items }) => (
  <ul style={{ listStyle: "none", paddingLeft: "24px", ...style }}>
    {items.map((item, i) => (
      <li key={`arrow-${i}`} style={{ marginBottom: "8px" }}>
        <Icon
          style={{
            fontWeight: "bold",
            color: "#fff",
            marginRight: "8px",
            marginLeft: "-24px",
          }}
          type={"right"}
        />
        {item}
      </li>
    ))}
  </ul>
);

ListArrows.propTypes = {
  items: arrayOf(any.isRequired).isRequired,
  style: object,
};
