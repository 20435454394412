import React from "react";
import { object } from "prop-types";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const API_KEY = "AIzaSyCFH7bQ0WGzpvTWpoy6fMdwR5vWaoQ7sPo";
const STYLES = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        lightness: "27",
      },
      {
        saturation: "-14",
      },
      {
        gamma: "2.29",
      },
      {
        color: "#c4d7ed",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        color: "#e0e5ea",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
      {
        visibility: "simplified",
      },
      {
        color: "#fff9f9",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        color: "#b1adad",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
      {
        color: "#efdddd",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        saturation: "-61",
      },
      {
        lightness: "-86",
      },
      {
        gamma: "2.05",
      },
      {
        color: "#99a3c4",
      },
      {
        weight: "2",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aaaaaa",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
];

const Map = ({ style }) => (
  <div style={style}>
    <LoadScript
      id={"loadGoogleMapsScript"}
      googleMapsApiKey={API_KEY}
      onLoad={() => console.log("Google maps script loaded")}
      onError={() => console.log("Error loading google maps script")}
      region={"EN"}
      version={"weekly"}
      libraries={["drawing", "visualization", "places"]}
    >
      <GoogleMap
        id={"map"}
        mapContainerStyle={{ width: "100%", height: "405px" }}
        zoom={16.5}
        center={{ lat: -37.81162, lng: 144.95325 }}
        options={{ styles: STYLES }}
        styles={STYLES}
      >
        <Marker
          position={{ lat: -37.81162, lng: 144.95325 }}
          label={"pdView"}
        />
      </GoogleMap>
    </LoadScript>
  </div>
);

Map.propTypes = {
  style: object,
};

export default Map;
