import React from "react";
import { string, object } from "prop-types";
import { Menu } from "antd";

import "./menu.css";

import {
  ExternalLink,
  ExternalLinkTypes,
  InternalLink,
  InternalLinkTypes,
} from "../links";
import { CtaPrimary, CtaSecondary } from "../buttons";

// const services = Object.keys(ServicesData);
// let servicesMenu = [];
// services.forEach(s => {
//   const service = ServicesData[s];
//   servicesMenu.push(
//     <Menu.Item key={service.learnMoreLink}>
//       <InternalLink url={service.learnMoreLink}>{service.title}</InternalLink>
//     </Menu.Item>
//   );
// });

export const AppMenu = ({ style, mode, pageLink }) => (
  <div>
    <Menu mode={mode} selectedKeys={pageLink} style={style}>
      <Menu.Item key={"/home"}>
        <InternalLink url={InternalLinkTypes.Home}>Home</InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Services}>
        <InternalLink url={InternalLinkTypes.Services}>
          Our Services
        </InternalLink>
      </Menu.Item>
      {/*<Menu.SubMenu title={"Our Services"}>{servicesMenu}</Menu.SubMenu>*/}
      <Menu.Item key={InternalLinkTypes.About}>
        <InternalLink url={InternalLinkTypes.About}>About Us</InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Contact}>
        <InternalLink url={InternalLinkTypes.Contact}>Contact Us</InternalLink>
      </Menu.Item>
      <Menu.Item style={{ padding: mode === "horizontal" ? "0 4px" : null }}>
        <CtaSecondary width={"150px"} size={"default"} block={true}>
          <ExternalLink url={ExternalLinkTypes.Login}>LOGIN</ExternalLink>
        </CtaSecondary>
      </Menu.Item>
      <Menu.Item style={{ padding: mode === "horizontal" ? "0 4px" : null }}>
        <CtaPrimary width={"150px"} size={"default"} block={true}>
          <InternalLink url={InternalLinkTypes.FreeTrial}>
            FREE TRIAL
          </InternalLink>
        </CtaPrimary>
      </Menu.Item>
    </Menu>
  </div>
);

AppMenu.propTypes = {
  style: object,
  pageLink: string.isRequired,
  mode: string,
};
