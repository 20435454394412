import React from "react";
import { object, string } from "prop-types";
import { Row, Col } from "antd";

import { AppMenu } from "./menu";

const Nav = ({ style, pageLink }) => (
  <div style={{ display: "inline-block", ...style }}>
    <Row>
      <Col lg={24} md={0} sm={0} xs={0}>
        <AppMenu
          mode={"horizontal"}
          pageLink={pageLink}
          style={{ float: "left", borderBottom: 'none' }}
        />
      </Col>
    </Row>
  </div>
);

Nav.propTypes = {
  style: object,
  pageLink: string,
};

export default Nav;
