import { InternalLinkTypes, ExternalLinkTypes } from "../links";

export const ServicesData = {
  pdbidr: {
    title: "pdBidr",
    description: "Automated Bidding of Generators, Renewables and Batteries",
    descriptionList: [
      "maximise FCAS and energy earnings",
      "measure, manage and improve with comprehensive benchmarking",
      "hands off automated facility dispatch, or",
      "apply deep integration within sophisticated trading teams, and", 
      "interrogate and intervene in the solve cycle", 
      "includes adaptive machine learning price forecasts",
    ],
    targetAudienceList: [
      "gentailers, market participants",
      "scheduled plant including batteries and renewables",
    ],
    learnMoreLink: InternalLinkTypes.ServicesVadvisor,
    watchVideoLink: ExternalLinkTypes.VideoVadvisor,
    freeTrialLink: InternalLinkTypes.FreeTrialVadvisor,
    kbLink: ExternalLinkTypes.KnowledgeBaseVadvisor,
    loginLink: ExternalLinkTypes.Login,
    categoryName: "automated bidding platform",
  },
  fcaspays: {
    title: "FCASpays",
    description: "Regulation and Frequency 4 Second Data",
    descriptionList: [
      "measure generator performance",
      "analyse frequency and generator behaviour",
      "understand the gross margin in providing regulation FCAS",
      "understand your liabilities and contribution factor",
      "direct data access to database available",
    ],
    targetAudienceList: [
      "plant exposed to regulation FCAS cost recovery",
      "providers of regulation FCAS",
      "regulators and regulatory teams interested in frequency and FCAS market design",
    ],
    learnMoreLink: InternalLinkTypes.ServicesFcasPays,
    watchVideoLink: ExternalLinkTypes.VideoFcasPays,
    freeTrialLink: InternalLinkTypes.FreeTrialFcasPays,
    kbLink: ExternalLinkTypes.KnowledgeBaseFcasPays,
    loginLink: ExternalLinkTypes.Login,
    categoryName: "enriching data",
  },
  aeg: {
    title: "DAEG / WAEG",
    description: "Day, Hour and Week Ahead Expected Generation",
    descriptionList: [
      "predispatch, p5min and p7day generation profiles",
      "updated every 5 and 30 minutes",
      "summary views, events",
      "changes between rundatetimes and settlementdates highlighted",
      "download data via API",
    ],
    targetAudienceList: ["physical traders", "energy contract traders ", "fuel procurement", "outage planners"],
    learnMoreLink: InternalLinkTypes.ServicesAEG,
    watchVideoLink: ExternalLinkTypes.VideoAEG,
    freeTrialLink: InternalLinkTypes.FreeTrialAEG,
    kbLink: ExternalLinkTypes.KnowledgeBaseAEG,
    loginLink: ExternalLinkTypes.Login,
    categoryName: "algorithmic data",
  },
  pd4castr: {
    title: "pd4castr",
    description: "Machine learning price forecasts ",
    descriptionList: [
      "Regular model retraining updates",
      "Performance metrics included",
      "Input data and price forecast charts",
"      Rundatetime comparison charts",
      "Week ahead 1/2 hour forecasts",
      "Predispatch time horizon 1/2 hour forecasts",
      
    ],
    targetAudienceList: [
      "physical and contract traders",
      "middle and back office for independent price forecasts",
      "fuel procurement and outage planning",
      "input into pdBidr"],
    learnMoreLink: '/services/pd4castr',
    watchVideoLink: ExternalLinkTypes.VideoAEG,
    freeTrialLink: InternalLinkTypes.FreeTrialAEG,
    kbLink: ExternalLinkTypes.KnowledgeBaseAEG,
    loginLink: ExternalLinkTypes.Login,
    categoryName: "Forecasting Platform",
  },
};
