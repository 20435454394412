import React from "react";
import Intercom from "react-intercom";

const LiveChat = () => {
  return <Intercom className={"live-chat"} appID={"dj3h8cb0"} />;
};

LiveChat.propTypes = {};

export default LiveChat;
