import React from "react";
import { object, string } from "prop-types";
import { ContainerBackground, ContainerContentFixed } from "../containers";
import { CtaPrimary, CtaSecondary } from "../buttons";
import { InternalLink, InternalLinkTypes } from "../links";
import { Colors } from "../constants";
// import { VideoEmbedService } from "../videos";

export const ContentServiceSignUp = ({
  style,
  title,
  freeTrialLink,
  videoLink,
}) => (
  <ContainerBackground
    style={{
      textAlign: "center",
      background: "white",
      color: Colors.DARK_BLUE,
      ...style,
    }}
  >
    <ContainerContentFixed>
      <h2 style={{ color: "inherit", fontWeight: "500", fontSize: "1.92rem" }}>
        Want to try {title}?
      </h2>
     <h3 style={{ color: "inherit", fontSize: "1rem", marginBottom: "24px" }}>
        Contact us for a presentation  {freeTrialLink && 'or sign up for a free trial'}
      </h3>
      {freeTrialLink && <CtaPrimary style={{ width: "200px", margin: "8px 16px" }}>
        <InternalLink url={freeTrialLink}>FREE TRIAL</InternalLink>
      </CtaPrimary>}
      <CtaSecondary style={{ width: "200px", margin: "8px 16px" }}>
        <InternalLink url={InternalLinkTypes.Contact}>CONTACT US</InternalLink>
      </CtaSecondary>
      {/*{videoLink && (*/}
      {/*  <VideoEmbedService*/}
      {/*    url={videoLink}*/}
      {/*    style={{*/}
      {/*      width: "100%",*/}
      {/*      height: "auto",*/}
      {/*      minHeight: "300px",*/}
      {/*      marginTop: "16px",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
    </ContainerContentFixed>
  </ContainerBackground>
);

ContentServiceSignUp.propTypes = {
  style: object,
  title: string.isRequired,
  freeTrialLink: string.isRequired,
  videoLink: string,
};
