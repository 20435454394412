import React, { Component } from "react";
import { object } from "prop-types";
import { Form, Input, Spin, Alert, Button, Row, Col } from "antd";
import axios from "axios";
import { Colors } from "../constants";

const FORM_STATUS = {
  OPEN: "OPEN",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_ERROR: "SUBMIT_ERROR",
};

class ContactForm extends Component {
  state = {
    confirmDirty: false,
    formStatus: FORM_STATUS.OPEN,
  };

  handleSubmit = e => {
    e.preventDefault();
    const self = this;
    this.props.form.validateFieldsAndScroll((err, fieldsValue) => {
      if (!err) {
        self.setState({
          formStatus: FORM_STATUS.SUBMITTING,
        });

        //const firstName = fieldsValue["first_name"];
        //const lastName = fieldsValue["last_name"];
        //const message = fieldsValue["message"];
        const email = fieldsValue["email"];
        //const phone = fieldsValue["phone"];

        const targetArn = encodeURIComponent(
          "arn:aws:sns:ap-southeast-2:734239462087:SendEmailToCompanyTopic"
        );
        const subject = encodeURIComponent(
          `Contact form submitted by ${email}`
        );
        const emailMessage = encodeURIComponent(
          JSON.stringify(fieldsValue, null, 0)
        );
        axios
          .get(
            `https://api.utils.pdview.com.au/sendemailtocompany?TargetArn=${targetArn}&Subject=${subject}&Message=${emailMessage}`,

            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function(response) {
            if (response && response.data && response.data.PublishResponse) {
              self.setState({
                formStatus: FORM_STATUS.SUBMIT_SUCCESS,
              });
            } else {
              console.error("Error submitting message", response);
              self.setState({
                formStatus: FORM_STATUS.SUBMIT_ERROR,
              });
            }
          })
          .catch(function(error) {
            console.error("Error submitting message", error);
            self.setState({
              formStatus: FORM_STATUS.SUBMIT_ERROR,
            });
          });
      }
    });
  };

  renderFormItemInput = (isDisabled, type, style) => {
    switch (type) {
      case "textarea":
        return (
          <Input.TextArea
            disabled={isDisabled}
            rows={4}
            type={"text"}
            style={style}
          />
        );

      default:
        return <Input disabled={isDisabled} type={type} style={style} />;
    }
  };

  renderFormItem = (label, field, type, isDisabled, isRequired, itemProps) => {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Form.Item
        key={field}
        style={{ marginBottom: "0px" }}
        label={label}
        {...itemProps}
      >
        {getFieldDecorator(field, {
          initialValue: "",
          rules: [
            {
              required: isRequired,
              message: `${label} is required`,
            },
          ],
        })(this.renderFormItemInput(isDisabled, type, { top: "-8px" }))}
      </Form.Item>
    );
  };

  render() {
    const { formStatus } = this.state;
    const disableEdit =
      formStatus === FORM_STATUS.SUBMITTING ||
      formStatus === FORM_STATUS.SUBMIT_SUCCESS;

    return (
      <div
        style={{
          backgroundColor: Colors.LIGHT_GRAY,
          padding: "16px 32px",
          borderRadius: "4px",
        }}
      >
        <h2 style={{ fontWeight: "bold", fontSize: "1rem" }}>
          Send an Enquiry
        </h2>
        <Form onSubmit={this.handleSubmit} labelAlign={"left"}>
          <Row gutter={16}>
            <Col sm={12}>
              {this.renderFormItem(
                "First name",
                "first_name",
                "text",
                disableEdit,
                true
              )}
            </Col>
            <Col sm={12}>
              {this.renderFormItem(
                "Last name",
                "last_name",
                "text",
                disableEdit,
                true
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={12}>
              {this.renderFormItem("Email", "email", "text", disableEdit, true)}
            </Col>
            <Col sm={12}>
              {this.renderFormItem(
                "Phone",
                "phone",
                "text",
                disableEdit,
                false
              )}
            </Col>
          </Row>
          {this.renderFormItem(
            "Message",
            "message",
            "textarea",
            disableEdit,
            true
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape={"round"}
              disabled={disableEdit}
              size={"large"}
              style={{ marginBottom: "8px" }}
            >
              Send message
            </Button>
            {formStatus === FORM_STATUS.SUBMITTING && (
              <Spin size="large">
                <Alert
                  showIcon
                  message="Sending your message..."
                  type="info"
                  description="Please wait while we send your message. This may take a few seconds."
                />
              </Spin>
            )}
            {formStatus === FORM_STATUS.SUBMIT_SUCCESS && (
              <Alert
                type="success"
                showIcon
                message="Your message has been sent. We will be in touch soon."
                description=""
              />
            )}
            {formStatus === FORM_STATUS.SUBMIT_ERROR && (
              <Alert
                type="error"
                showIcon
                message="Something went wrong"
                description="There was an error in sending your message. Try sending again. If error persists, contact pdView via chat or directly via email."
              />
            )}
          </Form.Item>
        </Form>{" "}
      </div>
    );
  }
}

ContactForm.propTypes = {
  style: object,
};

export default Form.create()(ContactForm);
