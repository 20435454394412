import React from "react";
import { string } from "prop-types";
import { Layout } from "antd";

import { LogoHeader } from "../logos";
import { Nav } from "./";
import { InternalLink, InternalLinkTypes } from "../links";

const Header = ({ siteTitle, pageLink }) => (
  <Layout.Header
    style={{
      background: `#fff`,
      height: "auto",
      lineHeight: "100%",
      padding: "0 10%",
      boxShadow: "-1px -7px 11px 0px #000000",
      zIndex: 2,
    }}
  >
    <div style={{ float: "left" }}>
      <InternalLink url={InternalLinkTypes.Home}>
        <LogoHeader style={{ maxWidth: "180px", marginTop: "8px" }} />
      </InternalLink>
    </div>
    <Nav style={{ marginTop: "16px", float: "right" }} pageLink={pageLink} />
  </Layout.Header>
);

Header.propTypes = {
  siteTitle: string,
  pageLink: string,
};

Header.defaultProps = {
  siteTitle: ``,
  pageLink: "",
};

export default Header;
