import React from "react";
import { object } from "prop-types";
import LogoWhite from "../../images/pdview-icon-banner.svg";

export const LogoIconBanner = ({ style }) => (
  <span style={{ display: "inline-block", ...style }}>
    <LogoWhite style={{ width: "100%", height: "auto" }} />
  </span>
);

LogoIconBanner.propTypes = {
  style: object,
};
