import React from "react";
import { object } from "prop-types";
import Logo from "../../images/pdview-icon.svg";

const LogoIcon = ({ style }) => (
  <span style={{ display: "inline-block", ...style }}>
    <Logo style={{ width: "100%", height: "auto" }} />
  </span>
);

LogoIcon.propTypes = {
  style: object,
};

export default LogoIcon;
